// 刘志坤 V2.3.0
<template>
  <template v-if="pageLoading">
    <div class="base-apply-list">
      <a-spin size="large" class="loading-animation" />
    </div>
  </template>
  <template v-else>
    <section>
      <section class="form-style">
        <a-form layout="inline">
          <!-- <a-form-item label="" @click="clickFocus">
            <a-input
              class="form-input"
              allowClear
              v-model:value.trim="searchVal"
              :maxlength="30"
              placeholder="请输入印章名称或盖印人搜索"
              ref="inputbox"
            >
              <template #prefix>
                <img src="@/assets/svg/search.svg" />
              </template>
            </a-input>
          </a-form-item> -->
          <!--        <a-form-item label="盖印时间：" name="submitTime">-->
          <!--          <a-range-picker-->
          <!--          :disabledDate="disabledDate"-->
          <!--          separator="→"-->
          <!--          allowClear-->
          <!--          v-model:value="submitTime" >-->
          <!--          <template #suffixIcon>-->
          <!--              <CalendarOutlined />-->
          <!--            </template>-->
          <!--          </a-range-picker>-->
          <!--        </a-form-item>-->
          <!-- <a-form-item label="用印状态：">
          <a-select v-model:value="sealStatus" class="form-select" allowClear placeholder="请选择">
            <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item> -->
          <!--印章名称  -->
          <a-form-item label="印章名称：" name="sealName">
            <a-input
              style="width: 140px; height: 32px"
              v-model:value.trim="sealName"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- 盖印人 -->
          <a-form-item label="盖印人：" name="sealer">
            <a-input
              style="width: 140px; height: 32px"
              v-model:value.trim="sealer"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- 盖印人部门 -->
          <a-form-item label="盖印人部门：" name="sealerDept">
            <DeptSelect v-model:deptList="deptList" style="width: 225px;" />
          </a-form-item>
          <!-- 盖印时间 -->
          <a-form-item label="盖印时间：" name="stampingTime">
            <a-range-picker
              :disabledDate="disabledDate"
              separator="→"
              allowClear
              v-model:value="stampingTime"
              style="width: 240px; height: 32px"
            >
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
          <a-form-item class="form-btns">
            <a-button @click="reset">重置</a-button>
            <a-button class="form-btn" @click="searchList">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <section class="table-style">
        <div class="table-head">
          <span class="title">用印列表</span>
          <div class="btn-block">
            <a-button class="add-btn" @click="exportTable" v-btn="'PC_SEAL_LOG_EXPORT'"
              ><i class="iconfont icon-daochu head-export" />导出</a-button
            >
          </div>
        </div>
        <a-table
          :columns="fingerPrintColumns"
          :dataSource="dataSource"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="tableLoading"
          :rowKey="r => `${r.documentId}-${r.sealId}`"
          ref="tableRef"
          :scroll="{ x: 400 }"
        >
          <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
          <template #count>1</template>
          <!--部门 -->
          <template #departmentName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 10">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <template #sealTime="{ text }">
            <span class="seal-list" :title="text">{{ text ?? '-' }}</span>
          </template>
          <template #action="{ record }">
            <a class="table-text-color" @click="$emit('file-modal', record.documentId, 'send')">查看</a>
          </template>
        </a-table>
      </section>
    </section>
  </template>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { fingerPrintColumns } from '../columns'
import { getFingerPrintList, exportFingerList, getFingerprintDetail } from '@/apis/seal'
import { cmsNotice, setTablePageFooterStyle } from '@/utils/utils'
import { CalendarOutlined } from '@ant-design/icons-vue'
import { examineStatusObj, sealStatusObj, paginationParams } from '@/utils/constData'
import moment from 'moment'
import { useStore } from 'vuex'
import DeptSelect from '../../../businessManage/staff/components/staffModal/deptSelect.vue'

const typeList = [
  {
    id: 3,
    name: '待归档'
  },
  {
    id: 5,
    name: '已归档'
  },
  {
    id: 4,
    name: '归档中'
  }
]
export default defineComponent({
  name: 'fingerprint-list',
  props: {
    userId: {
      type: Number,
      default: 1
    },
    reload: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: undefined
    }
  },
  components: {
    // tableHeader,
    CalendarOutlined,
    DeptSelect
  },
  setup(props, ctx) {
    const pageLoading = ref(true)
    const inputbox = ref(null)
    const { emit } = ctx
    const tableRef = ref()
    const state = reactive({
      searchVal: undefined,
      // submitTime: [],
      sealStatus: props.status,
      timeSort: '',
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      fingerPrintColumns,
      tableLoading: true,
      currentNum: 1,
      sealName: undefined, // 印章名称
      sealer: undefined, // 盖印人
      stampingTime: [] // 盖印时间
    })
    const deptList = ref([])

    const store = useStore()

    const getList = async () => {
      state.tableLoading = true

      let departmentIdLists = []
      deptList.value.forEach(item => {
        departmentIdLists.push(item.departmentId)
      })
      console.log('部门id列表', departmentIdLists, '格式化后', departmentIdLists.join(','))

      const params = {
        // TODO:搜索请求参数[确认]
        sealStatus: state.sealStatus, //用印状态不可用0，待用印1，用印中2，待归档3，归档中4,已归档5
        timeSort: state.timeSort, //为空倒叙,1为正序排列
        // search: state.searchVal,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        startTime: state.stampingTime ? moment(state.stampingTime[0]).format('YYYY-MM-DD') : '', //盖印时间区间筛选-开始 yyyy-mm-dd
        endTime: state.stampingTime ? moment(state.stampingTime[1]).format('YYYY-MM-DD') : '', //盖印时间区间筛选-结束 yyyy-mm-dd
        sealName: state.sealName, //印章名称
        staffName: state.sealer, //盖印人
        departmentIdList: departmentIdLists.length ? departmentIdLists.join(',') : '' //部门查询id集合
      }
      if (!state.stampingTime.length) {
        params.startTime = ''
        params.endTime = ''
      }
      const res = await getFingerPrintList(params)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      // 排序
      state.timeSort = ''
      if (order == 'ascend') {
        state.timeSort = 1
      }
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const exportTable = () => {
      let sessionId = localStorage.getItem('yda-qywx-sessionId')
      if (sessionId == 'null' || !sessionId) {
        sessionId = undefined
      }
      window.location = exportFingerList({ sealStatus: state.sealStatus, search: state.searchVal, sessionId })
      setTimeout(() => {
        cmsNotice('success', '正在为您导出，请耐心等待...')
      }, 200)
    }

    // 搜索
    const searchList = () => {
      state.pagination.current = 1
      getList()
    }
    // 日期选择器：当天之后不可选
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    }

    // 重置
    const reset = () => {
      // ;(state.searchVal = ''), (state.submitTime = [])
      state.sealName = undefined //清空印章名称
      state.sealer = undefined //清空盖印人
      state.stampingTime = [] //清空盖印时间
      deptList.value = [] //清空盖印人部门
      // state.searchVal = ''
      state.pagination.current = 1
      getList()
    }
    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }
    onMounted(async () => {
      const params = store.state.sealManage.sealFingerParams
      if (params) {
        state.searchVal = params.searchVal
      }
      await getList()
      pageLoading.value = false
    })
    watch(
      () => props.reload,
      () => getList()
    )

    watch(
      () => state.searchVal,
      () => {
        store.commit('sealManage/SET_SEALFINGERPARAMS', {
          searchVal: state.searchVal
        })
      }
    )

    return {
      ...toRefs(state),
      searchList,
      reset,
      exportTable,
      handleTableChange,
      typeList,
      sealStatusObj,
      examineStatusObj,
      clickFocus,
      inputbox,
      disabledDate,
      pageLoading,
      deptList
    }
  }
})
</script>

<style lang="scss" scoped>
.base-apply-list {
  min-height: calc(100vh - 180px);
}
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dottos {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.form-input {
  width: 300px;
}
</style>
